$(document).on('turbolinks:load', function() {
  const BASE_URL = '/account/onboarding/user_addresses/'

  function countrySelection() {
    $('#country-selection').on('change', function() {
      var country_code = $("#country-selection").val();
      $('#state-selection').empty();
      $('#city-selection').empty();
      $.ajax({
        url: `${BASE_URL}get_states?country_code=${country_code}`,
        method: 'GET',
        dataType: 'json',
        success: function(response) {
          var states = response.states;
          var options = '';

          $.each(states, function(key, value) {
            options += '<option value="' + key + '">' + value + '</option>';
          });

          $('#state-selection').html(options);
          $('#state-selection').val('');
        },
        error: function(error) {
          console.log('Error:', error);
        }
      });
    });
  }

  function populateCities(url, cityVal=null) {
    $.ajax({
      url: `${BASE_URL}${url}`,
      method: 'GET',
      dataType: 'json',
      success: function(response) {
        var cities = response.cities;
        var options = '';
        $.each(cities, function(index, city) {
          options += '<option value="' + city + '">' + city + '</option>';
        });

        $('#city-selection').html(options);
        $('#city-selection').val('');
        if (cityVal) {
          $('#city-selection').val(cityVal);
          toggleCityFields();
        }
      },
      error: function(xhr, status, error) {
        console.log(error);
      }
    });
  }

  function stateSelection() {
    $('#state-selection').on('change', function() {
      var state_code = $("#state-selection").val();
      var country_code = $("#country-selection").val();
      $('#city-selection').empty();
      populateCities(`get_cities?state_code=${state_code}&country_code=${country_code}`);
    });
  }

  countrySelection();
  stateSelection();
  if ($('#city-selection').length > 0) {
    populateCities(`get_default_cities`);
  }
  $('#city-selection').select2();
  var checkbox = document.getElementById('manual_city_checkbox');
  var manualCityField = document.getElementById('manual-city-field');
  var stateValue = document.getElementById('state-selection')?.value;
  var countryValue = document.getElementById('country-selection')?.value;

  var citySelect = document.getElementById('city-selection');
  var cityDropdown = document.getElementById('city-select-dropdown');
  if ($("#city-selection-manual").val()) {
    populateCities(`get_cities?state_code=${stateValue}&country_code=${countryValue}`, $("#city-selection-manual").val());
  }

  const citySelectDropdown = document.getElementById('city-selection');
  const manualCityInput = document.getElementById('city-selection-manual');

  function toggleCityFields() {
    const isManualCity = citySelect?.value == '';
    if (manualCityField) {
      manualCityField.style.display = isManualCity ? 'block' : 'none';
      cityDropdown.style.display = isManualCity ? 'none' : 'block';
      $(`#manual_city_checkbox`).prop('checked', isManualCity);
    }
  }
  
  $('#city-selection').on('select2:select', function (e) {
    if (e?.target) {
      manualCityInput.value=e.target.value
      toggleCityFields();
    }
  });

  toggleCityFields();

  if (checkbox) {
    checkbox.addEventListener('change', function() {
      if (this.checked) {
        cityDropdown.style.display = 'none';
        manualCityField.style.display = 'block';
        manualCityInput.value=""
        citySelect.removeAttribute('required'); // Remove required from city dropdown
        manualCityInput.setAttribute('required', 'required'); // Add required to manual input
      } else {
        cityDropdown.style.display = 'block';
        manualCityField.style.display = 'none';
        citySelect.setAttribute('required', 'required'); // Add required to city dropdown
        manualCityInput.removeAttribute('required'); // Remove required from manual input
      }
    });
  }
});
