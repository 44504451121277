document.addEventListener('turbolinks:load', function () {
  function character_counter(event, id, limit_text) {
    var formElement = $(`.tinymce`).parents('form')
    const maxLength = $(`#${id}`)[0].maxLength
    var textLength = tinymce.activeEditor.plugins.wordcount.body.getCharacterCount()
    if (textLength >= maxLength) {
      limit_text.text(`${textLength} / ${maxLength}`)
      event.preventDefault()
      event.stopPropagation()
      if (textLength > maxLength) {
        formElement.find(':submit').prop('disabled', true)
      } else {
        formElement.find(':submit').prop('disabled', false)
      }
    } else {
      limit_text.text(`${textLength} / ${maxLength}`)
      formElement.find(':submit').prop('disabled', false)
    }
  }

  if ($('.tinymce').length > 0) {
    tinymce.remove();
    tinymce.init({
      selector: '.tinymce',
      min_height: 200,
      menubar: false,
      statusbar: false,
      contextmenu: false,
      plugins:
        'autoresize preview paste searchreplace autolink directionality  visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help',
      toolbar_groups: {
        headings: {
          text: 'headings',
          tooltip: 'headings',
          items: 'h1 h2 h3 h4 h5 h6',
        },
        alignment: {
          icon: 'align-justify',
          tooltip: 'alignment',
          items: 'alignleft aligncenter alignright alignjustify',
        },
      },
      toolbar:
        'bold italic strikethrough link underline fontfamily alignment forecolor backcolor headings blockquote hr numlist bullist outdent indent',

      paste_preprocess: function(plugin, args) {
        const maxLength = $(`#${args.target.id}`)[0].maxLength;
        let element = document.createElement("DIV");
        element.innerHTML = args.content;
        let plain_text = element.textContent || element.innerText || "";
        plain_text = plain_text.replace(/[\r\n]/gm, '')
        var textLength = args.target.plugins.wordcount.body.getCharacterCount() + plain_text.length;
        if (textLength > maxLength){
           args.content = "";
        }
      },
      toolbar_mode: 'floating',
      image_caption: true,
      setup: function (ed) {
        var limit_text = $(`#${ed.id}`).parent().find('.character-count')
        ed.on('init', function (args) {
          const maxLength = $(`#${ed.id}`)[0].maxLength
          var textLength = args.target.plugins.wordcount.body.getCharacterCount()
          limit_text.text(`${textLength} / ${maxLength}`)
        })

        ed.on('keypress', function (event) {
          character_counter(event, ed.id, limit_text)
        })

        ed.on('keyup', function (event) {
          character_counter(event, ed.id, limit_text)
        })
      },
    })
  }
})
