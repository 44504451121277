$(document).on('turbolinks:load', function() {
  $(document).on('click', '.content-menu-item', function(e) {
    var closed = $(document).find(".content-menu-item").not($(this));
    closed.each(function(index, menu) {
      $(menu).parent().siblings().find('ul.collapse').each(function(index, element) {
        $(element).collapse('hide');
      });
    });
  });
});
