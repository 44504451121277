$(document).on('turbolinks:load', function() {
  $(document).on('change', '#session_external_video_value, #mastermind_external_trailer_video_value, #upsell_external_video_value, #opt_in_external_trailer_video_value, #confirmation_page_external_video_value', function(event) {
    if (validateLink($(`#${event.target.id}`).val()) == true) {
      $('#externalVideoValueError').html('');
    }
    else{
      $('#externalVideoValueError').html('External video value is not a youtube/vimeo link.');
    }
  })

  function validateLink(input) {
    REGEX = /(?:http?:s:\/\/)?(?:www\.)?(vimeo|youtube)\.com\/(?:watch\?v=)?(.*?)(?:\z|$|&)/;
    if (input.match(REGEX)) {
      return true;
    }
    else {
      return false;
    }
  }
});

$(document).on("keydown keyup", "#seller_coupon_percent_off", function(event) {
  if(event.which==38 || event.which==40){
      event.preventDefault();
  }
});
