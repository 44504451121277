import Tribute from 'tributejs'
import 'spectrum-colorpicker'
var Trix = require('trix')

// only show the editor tool bar when the user is editing the field.
// inspired by https://github.com/basecamp/trix/issues/343 and `app/assets/stylesheets/account/fields/trix_editor.scss`

if ( $('trix-editor')) {
  // Add these elements to Trix before (trix-initialize)
  Array.from(["h2", "h3", "h4", "h5", "h6"]).forEach((headingType, i) => {
    Trix.config.blockAttributes[`heading${(i + 2)}`] = { tagName: headingType, terminal: true, breakOnReturn: true, group: false}
  })

  Array.from(["Arial", "Arial Black", "Comic Sans MS", "Courier New", "Georgia", "Impact", "Lucida Console", "Lucida Sans Unicode", "Palatino Linotype", "Rubik", "Sans Serif", "Serif", "Tahoma", "Times New Roman", "Trebuchet MS", "Verdana"]).forEach((font, i) => {
    Trix.config.textAttributes[`font${(i + 1)}`] = { style: { fontFamily: font }, inheritable: true, parser: e => e.style.fontFamily == font }
  })

  Trix.config.textAttributes.foregroundColor = {
    styleProperty: 'color',
    inheritable: true,
    terminal: true,
    exclusive: true
  }

  Trix.config.textAttributes.backgroundColor = {
    styleProperty: 'backgroundColor',
    inheritable: true,
    terminal: true,
    exclusive: true
  }

  Trix.config.textAttributes[`textAlignLeft`] = {
    style: {
      textAlign: 'left',
      display: 'block'
    },
    inheritable: true,
    terminal: true,
    parser: e => e.style.textAlign == 'left'
  }

  Trix.config.textAttributes[`textAlignCenter`] = {
    style: {
      textAlign: 'center',
      display: 'block'
    },
    inheritable: true,
    terminal: true,
    parser: e => e.style.textAlign == 'center'
  }

  Trix.config.textAttributes[`textAlignRight`] = {
    style: {
      textAlign: 'right',
      display: 'block'
    },
    inheritable: true,
    terminal: true,
    parser: e => e.style.textAlign == 'right'
  }

  Trix.config.textAttributes.underline = {
    style: { "textDecoration": "underline" },
    inheritable: true,
    parser: function(element) {
      return element.style.textDecoration === "underline";
    }
  }

}

$(document).on('trix-initialize', function(e) {
  addEventListener("trix-focus", updateTrixToolbarVisability);
  addEventListener("trix-blur", updateTrixToolbarVisability);
  updateTrixToolbarVisability();
  initializeTribute();

  setupToolbar(e.target);
})

function updateTrixToolbarVisability() {
  $("trix-editor").each(function (index, editorElement) {
    var toolbarElement = editorElement.toolbarElement;
    if (editorElement == document.activeElement) {
      $(toolbarElement).addClass('visible');
    } else {
      // don't hide the toolbar if we've unfocused to focus on the link dialog.
      if (!toolbarElement.contains(document.activeElement)) {
        $(toolbarElement).removeClass('visible');
      }
    }
  });
}

function initializeTribute() {
  $('trix-editor').each(function(index) {
    var editor = this.editor;

    var mentionConfig = {
      trigger: '@',
      values: JSON.parse(editor.element.dataset.mentions),
      selectTemplate: function (item) {
        item = item.original;
        return '<a href="' + item.protocol + '://' + item.model + '/' + item.id + '">' + item.label + '</a>';
      },
      menuItemTemplate: function (item) {
        return '<img src="' + item.original.photo + '" /> ' + item.string;
      },
      requireLeadingSpace: true,
      replaceTextSuffix: ''
    }

    var topicConfig = {
      trigger: '#',
      values: JSON.parse(editor.element.dataset.topics),
      selectTemplate: function (item) {
        item = item.original;
        return '<a href="' + item.protocol + '://' + item.model + '/' + item.id + '">' + item.label + '</a>';
      },
      menuItemTemplate: function (item) {
        return '<img src="' + item.original.photo + '" /> ' + item.string;
      },
      requireLeadingSpace: true,
      replaceTextSuffix: ''
    }

    var tribute = new Tribute({
      collection: [topicConfig, mentionConfig],
    });

    tribute.attach(this);

    editor.element.addEventListener('keydown', function(event) {
    //   if (event.keyCode == 13) {
    //     // TODO there has got to be a better fix than this.
    //     // apparently old, removed-from-the-page trix editors are colliding with new ones.
    //     if ($(editor.element).closest('body').length == 0) {
    //       return;
    //     }
    //
    //     if (tribute.isActive) {
    //       return;
    //     }
    //
    //     if (editor.element.dataset.enter == 'submit') {
    //       event.preventDefault();
    //       return $(editor.element).closest('form').submit();
    //     }
    //   }
      if (editor.element.dataset.enter == 'submit' && event.key == 'Enter') {
        console.log(event);
        event.preventDefault();
        return $(editor.element).closest('form').submit();
      }
    });

    // tribute.range.pasteHtml = function(html, startPos, endPos) {
    //   for (var i = endPos; i > startPos; i--) {
    //     editor.deleteInDirection("backward")
    //   }
    //   editor.insertHTML(html)
    // }

  })
}

function dialogFontColorTemplate(trixId) {
  let outputString =  `<div class="trix-dialog trix-dialog--font-color" data-trix-dialog="x-font-color" data-trix-dialog-attribute="x-font-color">
      <div class="trix-dialog__link-fields">
        <input type="text" name="x-font-color" class="trix-dialog-hidden__input" data-trix-input>
        <div class="trix-color-group">`
        outputString += `<input type='text' id="fontColorPicker`+ trixId + `" />`
        outputString += `</div>
        <div class="cancel-format-button">
          <button type="button" class="trix-button trix-button--dialog" data-trix-method="removeAttribute" data-trix-action="x-clear-font-color">Clear Formatting</button>
        </div>
      </div>
    </div> `
  return outputString
}

function dialogBackgroundColorTemplate(trixId) {
  let outputString =  ` <div class="trix-dialog trix-dialog--background-color" data-trix-dialog="x-background-color" data-trix-dialog-attribute="x-background-color">
      <div class="trix-dialog__link-fields">
        <input type="text" name="x-color" class="trix-dialog-hidden__input" data-trix-input>
        <div class="trix-color-group">`
        outputString += `<input type='text' id="backgroundColorPicker` + trixId + `" />`
        outputString += `</div>
        <div class="cancel-format-button">
          <button type="button" class="trix-button trix-button--dialog" data-trix-method="removeAttribute" data-trix-action="x-clear-background-color">Clear Formatting</button>
        </div>
      </div>
    </div>`
  return outputString
}

// creating the buttons for adding styles
class RichText {
  constructor(element, trixId) {
    this.element = element

    this.insertHeadingElements()
    this.insertDividerElements()
    this.insertUnderlineButton()
    this.insertFontElements()
    this.insertColorElements(trixId)
  }

  insertHeadingElements() {
    this.removeOriginalHeadingButton()
    this.removeCodeButton()
    this.insertNewHeadingButton()
    this.insertHeadingDialog()
  }

  removeOriginalHeadingButton() {
    if(this.originalHeadingButton) {
      this.buttonGroupBlockTools.removeChild(this.originalHeadingButton)
    }
  }

  removeCodeButton() {
    if(this.codeButton) {
      this.buttonGroupBlockTools.removeChild(this.codeButton)
    }
  }

  insertNewHeadingButton() {
    this.buttonGroupBlockTools.insertAdjacentHTML("afterbegin", this.headingButtonTemplate)
  }

  insertHeadingDialog() {
    this.dialogsElement.insertAdjacentHTML("beforeend", this.dialogHeadingTemplate)
  }

  insertDividerElements() {
    this.quoteButton.insertAdjacentHTML("afterend", this.horizontalButtonTemplate)
  }

  insertColorElements(trixId) {
    this.insertFontColorButton()
    this.insertDialogFontColor(trixId)
    this.insertBackgroundColorButton()
    this.insertDialogBackgroundColor(trixId)
  }

  insertUnderlineButton() {
    this.toolbarElement.querySelector("[data-trix-button-group=text-tools]").insertAdjacentHTML("beforeend", this.underlineButtonTemplate)
  }

  insertFontElements() {
    this.insertFontButton()
    this.insertDialogFont()
    this.insertTextAlignButton()
    this.insertDialogTextAlign()
  }

  insertFontColorButton() {
    this.buttonGroupTextTools.insertAdjacentHTML("beforeend", this.fontColorButtonTemplate)
  }

  insertDialogFontColor(trixId) {
    this.dialogsElement.insertAdjacentHTML("beforeend", dialogFontColorTemplate(trixId))
  }

  insertBackgroundColorButton() {
    this.buttonGroupTextTools.insertAdjacentHTML("beforeend", this.backgroundColorButtonTemplate)
  }

  insertDialogBackgroundColor(trixId) {
    this.dialogsElement.insertAdjacentHTML("beforeend", dialogBackgroundColorTemplate(trixId))
  }

  insertFontButton() {
    this.buttonGroupTextTools.insertAdjacentHTML("beforeend", this.fontButtonTemplate)
  }

  insertDialogFont() {
    this.dialogsElement.insertAdjacentHTML("beforeend", this.dialogFontTemplate)
  }

  insertTextAlignButton() {
    this.buttonGroupTextTools.insertAdjacentHTML("beforeend", this.textAlignButtonTemplate)
  }

  insertDialogTextAlign() {
    this.dialogsElement.insertAdjacentHTML("beforeend", this.dialogTextAlignTemplate)
  }

  get buttonGroupBlockTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=block-tools]")
  }

  get buttonGroupTextTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=text-tools]")
  }

  get dialogsElement() {
    return this.toolbarElement.querySelector("[data-trix-dialogs]")
  }

  get originalHeadingButton() {
    return this.toolbarElement.querySelector("trix-toolbar [data-trix-attribute=heading1]")
  }

  get codeButton() {
    return this.toolbarElement.querySelector("trix-toolbar [data-trix-attribute=code]")
  }

  get quoteButton() {
    return this.toolbarElement.querySelector("trix-toolbar [data-trix-attribute=quote]")
  }

  get toolbarElement() {
    return this.element.toolbarElement
  }

  get horizontalButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-horizontal-rule trix-additional" data-trix-action="x-horizontal-rule" tabindex="-1" title="Divider">Divider</button>'
  }

  get headingButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-heading-0 trix-additional" data-trix-action="x-heading" title="Heading" tabindex="-1">Heading</button>'
  }

  get fontButtonTemplate() {
    return '<button type="button" class="trix-button trix-additional" data-trix-action="x-font" title="Font Style" tabindex="-1">Font</button>'
  }

  get fontColorButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-font-color trix-additional" data-trix-action="x-font-color" title="Font Color" tabindex="-1">Font Color</button>'
  }

  get backgroundColorButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-font-background trix-additional" data-trix-action="x-background-color" title="Background Color" tabindex="-1">Background Color</button>'
  }

  get textAlignButtonTemplate() {
    return '<button type="button" class="trix-button ti-align-justify trix-additional" data-trix-action="x-text-align" title="Text Align" tabindex="-1"></button>'
  }

  get underlineButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-underlined" title="Underline" data-trix-attribute="underline" data-trix-key="u">Underline</button>'
  }

  get dialogFontTemplate() {
    return `
      <div class="trix-dialog trix-dialog--font" data-trix-dialog="x-font"  data-trix-dialog-attribute="x-font">
        <div class="trix-dialog__font-fields">
          <input type="text" name="x-font" class="trix-dialog-hidden__input" data-trix-input>
          <div class="trix-button-group">
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font1" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font2" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font3" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font4" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font5" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font6" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font7" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font8" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font9" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font10" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font11" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font12" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font13" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font14" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font15" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="font16" data-trix-method="hideDialog"></button>
          </div>
          <div class="cancel-format-button">
            <button type="button" class="trix-button trix-button--dialog" data-trix-method="removeAttribute" data-trix-action="x-clear-font">Clear Formatting</button>
          </div>
        </div>
      </div>
    `
  }

  get dialogHeadingTemplate() {
    return `
      <div class="trix-dialog trix-dialog--heading" data-trix-dialog="x-heading" data-trix-dialog-attribute="x-heading">
        <div class="trix-dialog__link-fields">
          <input type="text" name="x-heading" class="trix-dialog-hidden__input" data-trix-input>
          <div class="trix-button-group">
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading1">H1</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading2">H2</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading3">H3</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading4">H4</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading5">H5</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading6">H6</button>
          </div>
        </div>
      </div>
    `
  }

  get dialogTextAlignTemplate() {
    return `
      <div class="trix-dialog trix-dialog--text-align" data-trix-dialog="x-text-align" data-trix-dialog-attribute="x-text-align">
        <div class="trix-dialog__link-fields">
          <input type="text" name="x-text-align" class="trix-dialog-hidden__input" data-trix-input>
          <div class="trix-button-group">
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="textAlignLeft">Left</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="textAlignCenter">Center</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="textAlignRight">Right</button>
          </div>
        </div>
      </div>
    `
  }
}

function createColorPickers(activeEditor, trixId) {
  $("#fontColorPicker" + trixId).spectrum({
    showInput: true,
    flat: true,
    preferredFormat: 'rgb',
    clickoutFiresChange: false,
    change: function(color) {
      activeEditor.editor.activateAttribute("foregroundColor", color.toRgbString());
    },
    hide: function() {
      activeEditor.editor.dectivateAttribute("foregroundColor");
    }
  });

  $("#backgroundColorPicker" + trixId).spectrum({
    showInput: true,
    flat: true,
    preferredFormat: 'rgb',
    clickoutFiresChange: false,
    change: function(color) {
      activeEditor.editor.activateAttribute("backgroundColor", color.toRgbString());
    },
    hide: function() {
      activeEditor.editor.dectivateAttribute("backgroundColor");
    }
  });
}


function setupToolbar(event){

  // Prevents multiple instances of custom /buttons
  var activeEditor = event
  var trixId = $(activeEditor).attr('trix-id')
  if( !$(activeEditor).hasClass('plain-editor') ) {
    new RichText(activeEditor, trixId)
    createColorPickers(activeEditor, trixId)
  }
  $('<h6>Link must include http://</h6>').insertBefore('#trix-toolbar-' + trixId + ' .trix-dialog--link .trix-dialog__link-fields')




  addEventListener("trix-action-invoke", function (event) {
    if (event.actionName == "x-horizontal-rule") insertHorizontalRule()

    function insertHorizontalRule() {
      event.target.editor.insertAttachment(buildHorizontalRule())
    }

    function buildHorizontalRule() {
      return new Trix.Attachment({ content: "<hr>", contentType: "vnd.rubyonrails.horizontal-rule.html" })
    }

    if (event.actionName = "x-clear-background-color") {
      event.target.editor.deactivateAttribute("backgroundColor")
    }

    if (event.actionName = "x-clear-font-color") {
      event.target.editor.deactivateAttribute("foregroundColor")
    }

    if (event.actionName = "x-clear-font") {
      Array.from(["Arial", "Arial Black", "Comic Sans MS", "Courier New", "Georgia", "Impact", "Lucida Console", "Lucida Sans Unicode", "Palatino Linotype", "Rubik", "Sans Serif", "Serif", "Tahoma", "Times New Roman", "Trebuchet MS", "Verdana"]).forEach((font, i) => {
        event.target.editor.deactivateAttribute(`font${(i + 1)}`)
      });
    }
  })

  addEventListener('trix-paste', function (data) {
    if (data.paste.hasOwnProperty('html')) {
      var startIndex = data.paste.html.search('<style type="text/css">');
      if (startIndex != -1) {
        var endIndex = data.paste.html.search('</style>');
        var length  = '</style>'.length;
        var firstPart = data.paste.html.substring(0, startIndex);
        var endPart = data.paste.html.substring(endIndex+length, data.paste.html.length-1);
        data.paste.html = (firstPart + endPart).trim().replace(/<(.|\n)*?>/g, '').replace(/\n/g,"-");

      }
      data.target.editor.setSelectedRange(data.paste.range);
      data.target.editor.deactivateAttribute("backgroundColor");
      data.target.editor.deactivateAttribute("foregroundColor");
      data.target.editor.deactivateAttribute("bold");
      data.target.editor.deactivateAttribute("italic");
      data.target.editor.deactivateAttribute("strike");
      data.target.editor.deactivateAttribute("href");
      data.target.editor.deactivateAttribute("heading1");
      data.target.editor.deactivateAttribute("heading2");
      data.target.editor.deactivateAttribute("heading3");
      data.target.editor.deactivateAttribute("heading4");
      data.target.editor.deactivateAttribute("heading5");
      data.target.editor.deactivateAttribute("heading6");
      data.target.editor.deactivateAttribute("quote");
      data.target.editor.deactivateAttribute("code");
      data.target.editor.deactivateAttribute("bullet");
      data.target.editor.deactivateAttribute("number");

      Array.from(["Arial", "Arial Black", "Comic Sans MS", "Courier New", "Georgia", "Impact", "Lucida Console", "Lucida Sans Unicode", "Palatino Linotype", "Rubik", "Sans Serif", "Serif", "Tahoma", "Times New Roman", "Trebuchet MS", "Verdana"]).forEach((font, i) => {
        data.target.editor.deactivateAttribute(`font${(i + 1)}`)
      });
      var endOfText = data.target.textContent.length;
      data.target.editor.setSelectedRange(endOfText);
    }
  });
}
