// 🚫 DEFAULT RAILS INCLUDES
// This section represents the default includes for a Rails 6.0.0-rc1 application. Bullet Train's includes and your own
// includes should be specified at the end of the file, not in this section. This helps avoid merge conflicts in the
// future should the framework defaults change.

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// NOTE (HENRIQUE): In some cases this file seems to be loaded twice.
// This was verified when the turbolinks are being required and started,
// and it seems to be a known issue which can be fixed properly (https://stackoverflow.com/a/24625370).
// As a hotfix for this issue the below variable checks if rails/ujs
// has been started, and in such a case we don't run this file again
//  in order to prevent a JS exception we checked in bugsnag:
// "rails-ujs has already been loaded!"
import "@fortawesome/fontawesome-free/css/all"

if(!window._rails_loaded){

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// 🚫 DEFAULT BULLET TRAIN INCLUDES
// This section represents the default settings for a Bullet Train application. Your own includes should be specified
// at the end of the file. This helps avoid merge conflicts in the future, should we need to change our own includes.

global.$ = require('jquery')

require('webpack-jquery-ui');
require('webpack-jquery-ui/css');

// require("@popperjs/core")
require("popper.js")
global.bootstrap = require("bootstrap")
require("bootstrap-validator")
require("bootstrap-star-rating/js/star-rating.js")
require("bootstrap-star-rating/themes/krajee-svg/theme.js");
require("bootstrap-star-rating/css/star-rating.css");
require("bootstrap-star-rating/themes/krajee-svg/theme.css");
require("tether")
require("slick-carousel")
require("spectrum-colorpicker")
require("spectrum-colorpicker/spectrum.css")
require("chartkick")
require("chart.js")

// // These are the packages Light Admin depends on. We recommend only enabling the ones you need as you need them.
// // You'll also need to do an `yarn install {library}` before these work.
// require("chart.js")
// require("ckeditor")
// require("datatables.net")
// require("datatables.net-bs")
// require("dropzone")
// require("editable-table")
// require("fullcalendar")
// require("ion-rangeslider")
// require("jquery-bar-rating")
// require("moment")
// require("perfect-scrollbar")

// // For some of the libraries above, you also want to include their CSS.
require("slick-carousel/slick/slick.scss")
// require("perfect-scrollbar/dist/css/perfect-scrollbar.min.css")
// require("fullcalendar/dist/fullcalendar.min.css")
// require("datatables.net-bs/css/dataTables.bootstrap.min.css")
// require("dropzone/dist/dropzone")

// For inline use in `app/views/account/onboarding/user_details/edit.html.erb`.
global.jstz = require("jstz");

// ✅ YOUR APPLICATION'S INCLUDES
// If you need to customize your application's includes, this is the place to do it. This helps avoid merge
// conflicts in the future when Rails or Bullet Train update their own default includes.

require("jquery.countdown")

global.Cookies = require("js-cookie")

// Custom JavaScript for Bullet Train
require("../index")
require("../libs/data-confirm-modal")
require("../character_counter")
require("../password_validation")
require("iframe-resizer")
require("../field_validator")
require("../user_details")
require("../other_cancellation_feedback_text_area")

// https://jtway.co/5-steps-to-add-remote-modals-to-your-rails-app-8c21213b4d0c
$(function() {
  const modal_holder_selector = '#modal-holder';
  const modal_selector = '.modal';

  $(document).on('click', 'a[data-modal]', function() {
    const location = $(this).attr('href');
    // Load modal dialog from server
    $.get(
      location,
      data => { $(modal_holder_selector).html(data) }
    ).done(function(){
    	$("#modal-holder .modal-title").prepend(
    		$("#modal-holder .element-header").html()
    	);
      $(modal_holder_selector).find(modal_selector).modal('show');
    });

    return false;
  });

  $(document).on('ajax:success', 'form[data-modal]', function(event){
    const [data, _status, xhr] = event.detail;
    const url = xhr.getResponseHeader('Location');
    if (url) {
      // Redirect to url
      window.location = url;
    } else {
      // Remove old modal backdrop
      $('.modal-backdrop').remove();
      // Update modal content
      const modal = $(data).find('body').html();
      $(modal_holder_selector).html(modal).find(modal_selector).modal('show');
    }

    return false;
  });
});
}


/* Confirmation before Leaving Page with unsaved changes */
$(function() {
  var msg, unsaved;
  msg = "This page is asking you to confirm that you want to leave - data you have entered may not be saved.";
  unsaved = false;

  $(document).on('input', 'form[method=post]:not([data-remote]) :input, .trix-content', function() {
    return unsaved = true;
  });

  $(document).on('turbolinks:load', function() {
    return setTimeout((function() {
      return unsaved = false;
    }), 10);
  });

  $(document).on('submit', 'form[method=post]', function() {
    unsaved = false;
  });

  $(window).bind('beforeunload', function() {
    if (unsaved) {
      return msg;
    }
  });

  $(document).on('turbolinks:before-visit', function(event) {
    if (unsaved && !confirm(msg)) {
      event.preventDefault();
      event.returnValue = '';
    }
  });
});
