$(document).on('turbolinks:load', function() {
  $('.show-character-count').each(function() {
    countCharacter($(this), true);
  });

  $('.show-character-count').on('input', function() {
    countCharacter($(this));
  });

  function countCharacter(input, onload = false) {
    if (input.attr('class') == undefined) {
      return;
    }
    var isTrix = input.attr('class').indexOf('trix-content') != -1;
    var id = isTrix ? input.attr('field_id') : input.attr('id');
    if ((isTrix && !onload) || (id == undefined)) {
      return;
    }

    id = id.replace(/_/g,"-");
    var maxLength = input.attr("maxlength");
    var currentLength = isTrix ? input.text().length : input.val().length;
    $(`#${id}-maxlength`).text(`${currentLength} / ${maxLength}`);
  }

  $('.trix-button').on('click', function(){
    var input = $(this).parents().eq(2).next();
    countCharacter(input);
  });
});


document.addEventListener("trix-change", function (event) {
  var id = event.target.getAttribute('field_id');
  var maxLength = parseInt(event.target.getAttribute('maxlength'))
  if (!event.target.classList.contains('show-character-count')) {
    return;
  }
  var previousState = $(`#${id}_state`).val();
  var newDocumentText = event.target.textContent;
  $(`#${id}_state`).val(newDocumentText);
  var currentLength = newDocumentText.length;
  var formElement = $(`#${id}`).parents('form');
  id = id.replace(/_/g,"-");

  if(currentLength > maxLength) {
    formElement.find(':submit').prop('disabled', true);
    $(`#${id}-warning`).css('display','block')
    currentLength = event.target.textContent.length;
    $(`#${id}-maxlength`).text(`${currentLength} / ${maxLength}`);
    // return;
  } else {
    formElement.find(':submit').prop('disabled', false);
    $(`#${id}-warning`).css('display','none')
  }
  $(`#${id}-maxlength`).text(`${currentLength} / ${maxLength}`);
});