// Make sure TOS checkboxes are unchecked at page load
$(document).on('turbolinks:load', function() {
  legal_consent_checkbox = $('.legal-consent-check');
  if (legal_consent_checkbox) {
    legal_consent_checkbox.checked = false;
  }
});

$(document).on('turbolinks:load', function() {
  legal_consent_checkbox = $('.modal-legal-check');
  if (legal_consent_checkbox) {
    legal_consent_checkbox.checked = false;
  }
});

$(document).on('click', '.legal-consent-check', function(event) {
  console.log(`clicked lega-consent-ckeck`);
  $legal_consent_agreed_at = $('.legal-consent-agreed-at');
  if (event.target.checked) {
    now = new Date().toUTCString();
    $legal_consent_agreed_at.val(now);
    $('.disclaimer-gated-button').prop('disabled', false);
    $('.disclaimer-gated-button').parent().tooltip('disable');
    // Do we need to store Mastermind buying disclaimer agreement
    console.log($legal_consent_agreed_at.data('store-disclaimer-consent-as-cookie'));
    if ($legal_consent_agreed_at.data('store-disclaimer-consent-as-cookie')) {
      console.log(`disclaimer clicked at: ${now}`);
      console.log(`for mastermind: ${$legal_consent_agreed_at.data('mastermind-id')}`);
      console.log(`for domain: ${MASTERMIND_BASE_HOSTNAME}`);
      Cookies.set(
        "disclaimer_agreed_for_mastermind_" + $legal_consent_agreed_at.data('mastermind-id') + "_at",
        now,
        { domain: MASTERMIND_BASE_HOSTNAME }
      );
    }
  } else {
    $('.disclaimer-gated-button').prop('disabled', true);
    $('.disclaimer-gated-button').parent().tooltip('enable');
    $legal_consent_agreed_at.val('');
  }
});

$(document).on('click', '.legal-consent-check-modal', function(event) {
  console.log(`modal clicked lega-consent-ckeck-modal`);
  $legal_consent_agreed_at = $('.legal-consent-agreed-at-modal');
  if (event.target.checked) {
    now = new Date().toUTCString();
    $legal_consent_agreed_at.val(now);
    $('.disclaimer-gated-button').prop('disabled', false);
    $('.disclaimer-gated-button').parent().tooltip('disable');
    // Do we need to store Mastermind buying disclaimer agreement
    console.log($legal_consent_agreed_at.data('store-disclaimer-consent-as-cookie'));
    if ($legal_consent_agreed_at.data('store-disclaimer-consent-as-cookie')) {
      console.log(`modal disclaimer clicked at: ${now}`);
      console.log(` modal for mastermind: ${$legal_consent_agreed_at.data('mastermind-id')}`);
      console.log(`modal for domain: ${MASTERMIND_BASE_HOSTNAME}`);
      Cookies.set(
        "disclaimer_agreed_for_mastermind_" + $legal_consent_agreed_at.data('mastermind-id') + "_at",
        now,
        { domain: MASTERMIND_BASE_HOSTNAME }
      );
    }
  } else {
    console.log($legal_consent_agreed_at.val());
    $('.disclaimer-gated-button').prop('disabled', true);
    $('.disclaimer-gated-button').parent().tooltip('enable');
    $legal_consent_agreed_at.val('');
  }
});

$(document).on('click', '.modal-legal-check', function(event) {
  console.log(`modal clicked modal-legal-check`);
  $legal_consent_agreed_at = $('.legal-consent-agreed-at');
  if (event.target.checked) {
    now = new Date().toUTCString();
    $legal_consent_agreed_at.val(now);
    $('.disclaimer-gated-button').prop('disabled', false);
    $('.disclaimer-gated-button').parent().tooltip('disable');
    // Do we need to store Mastermind buying disclaimer agreement
    console.log($legal_consent_agreed_at.data('store-disclaimer-consent-as-cookie'));
    if ($legal_consent_agreed_at.data('store-disclaimer-consent-as-cookie')) {
      console.log(`modal-legal-check clicked at: ${now}`);
      console.log(`modal-legal-check mastermind: ${$legal_consent_agreed_at.data('mastermind-id')}`);
      console.log(`modal-legal-check domain: ${MASTERMIND_BASE_HOSTNAME}`);
      Cookies.set(
        "disclaimer_agreed_for_mastermind_" + $legal_consent_agreed_at.data('mastermind-id') + "_at",
        now,
        { domain: MASTERMIND_BASE_HOSTNAME }
      );
    }
  } else {
    console.log($legal_consent_agreed_at.val());
    $('.disclaimer-gated-button').prop('disabled', true);
    $('.disclaimer-gated-button').parent().tooltip('enable');
    $legal_consent_agreed_at.val('');
  }
});
