$(document).on('turbolinks:load', function() {
  $(".products-tab-switch" ).click(function(event) {
    var tabId = $(this).attr("id");
    const teamId = $(this).attr("data-team-id");
    if (tabId.split("-")[2]>3) {
      $(`#view-all-${teamId}`).removeClass('d-none');
    } else {
      $(`#view-all-${teamId}`).addClass('d-none');
    }
  });
  $(".show-hide-btn" ).click(function(event) {
    const team_id = $(this).attr("data-team-id");
    const enrolled = $(this).attr("data-enrolled");
    const teamIndex = $(this).attr("data-team-index");
    const enrolledTab = $(`#enrolled-products-${team_id}`).attr('class').includes('active');
    if(enrolledTab && $(`#uniq-team-${team_id}`).is(":visible")){
      $(`#uniq-team-${team_id}`).hide();
      $(`#swiper-enrolled-products-${teamIndex}`).show();
      $(`#slider-team-${team_id}`).addClass('d-md-flex').show();
      $(`#show_more_${team_id}`).hide();
      $(`#view-all-${team_id}`).children(":first").html('View All');
    } else if(enrolledTab && $(`#uniq-team-${team_id}`).is(":hidden")){
      $(`#swiper-enrolled-products-${teamIndex}`).hide();
      $(`#slider-team-${team_id}`).removeClass('d-md-flex').hide();
      $(`#uniq-team-${team_id}`).show();
      $(`#show_more_${team_id}`).show();
      $(`#show_more_${team_id}`).children(":first").html('Show Less');
      $(`#view-all-${team_id}`).children(":first").html('View Less');
    } else if(!enrolledTab && $(`#other-team-${team_id}`).is(":hidden")){
      $(`#swiper-other-products-${teamIndex}`).hide();
      $(`#slider-team-${team_id}`).removeClass('d-md-flex').hide();
      $(`#other-team-${team_id}`).show();
      $(`#show_more_other_tab${team_id}`).show();
      $(`#show_more_other_tab${team_id}`).children(":first").html('Show Less');
      $(`#view-all-${team_id}`).children(":first").html('View Less');
    } else if(!enrolledTab && $(`#other-team-${team_id}`).is(":visible")){
      $(`#other-team-${team_id}`).hide();
      $(`#swiper-other-products-${teamIndex}`).show();
      $(`#slider-team-${team_id}`).addClass('d-md-flex').show();
      $(`#show_more_other_tab${team_id}`).hide();
      $(`#view-all-${team_id}`).children(":first").html('View All');
    }
  });
});
