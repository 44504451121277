$('body').on('click', '.module-expand', function() {
  $(this).find('i').toggleClass('fa-chevron-right fa-chevron-down');
});

$(document).on('reorder', function(event) {
  $('.mastermind-module').each(function(index) {
    $(this).find('.module-sessions-count').html(
      $(this).find('.mastermind-module-sessions .tr').length
    );
  });
});
