$(document).on('turbolinks:load', function() {
  const inputField = document.getElementById('message');

  const cancellationForm = document.getElementById('cancellation_reason_form');
  const submitButton = document.getElementById('cancellation-reason-submit-btn');

  if (cancellationForm) {
    cancellationForm.addEventListener('submit', function(event) {
      if ($('#message-area').attr('class') == '') {
        $('#cancellation_reason_other').val(inputField.value)
      }
      const selectedReason = document.querySelector('input[name="cancellation_reason"]:checked');
      if (!selectedReason) {
        event.preventDefault();  // Prevent form submission
        alert('Please select a cancellation reason.');
        submitButton.removeAttribute("data-disable-with",false)
        submitButton.removeAttribute("disabled",false)
      }
    });
  }

  $('input[name="cancellation_reason"]').on('click', function() {
    if ($(this).val() == 'Other' || $(this).attr('id') == 'cancellation_reason_other') {
      inputAttribute = true;
      $('#message-area').removeClass("d-none");
      $('#no-feedback').addClass("d-none");
    }
    else {
      inputAttribute = false;
      $('#message-area').addClass("d-none");
      $('#no-feedback').removeClass("d-none");
    }
    if ($('#message-area').attr('class') == '') {
      inputField.setAttribute('required', inputAttribute);
    }
  });

  $('#message').on('keyup', function() {
    $('#cancellation_reason_text').text($(this).val().length)
  });
});
