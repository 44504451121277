document.addEventListener("turbolinks:load",function () {
    let hoverTargetElement = false;
    let jobId = null;
    $(`[class="fas fa-info-circle hint sales-copy"]`)
        .tooltip()
        .on("mouseenter", function () {
            $(this).tooltip("show");
            hoverTargetElement = true;
        })
        .on("mouseleave", function () {
            hoverTargetElement = false;
            if (jobId == null) {
                let _this = this;
                jobId = setInterval(function () {
                    if ($(".tooltip:hover").length === 0 && hoverTargetElement === false) {
                        $(_this).tooltip("hide")
                        clearInterval(jobId);
                        jobId = null;
                    }
                }, 100);
            }
        });
});