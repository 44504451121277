$(document).on('turbolinks:load', function() {
    $('#opt_in_button_padding,#opt_in_button_font_size')
    .on('change', function(e) {
        exampleButton = $('.example-opt-in-button div');
        value = e.target.value;
        element = $(e.target).data('style');

        $(exampleButton).css(element,value+'px');
    });

    $('#opt_in_button_color')
    .on('change', function(e) {
        exampleButton = $('.example-opt-in-button div');
        value = e.target.value;
        console.log(value)
        $(exampleButton).css('background-color',value);
    });

    $('#opt_in_button_text_color')
    .on('change', function(e) {
        exampleButton = $('.example-opt-in-button div');
        value = e.target.value;
        console.log(value)
        $(exampleButton).css('color',value);
    });

    $('#opt_in_button_text').on('input', function(e) {
        exampleButton = $('.example-opt-in-button div');
        $(exampleButton).text(e.target.value);
    });
    
});
