import consumer from "../channels/consumer";

function subscribeToEventsWithModel() {
  $("[data-events-with-model]").each(function(_, element) {
    var modelName= element.getAttribute('data-model');
    var recordId = element.getAttribute('data-id');

    consumer.subscriptions.create({
      channel: 'Sprinkles::EventsWithModelChannel',
      model_name: modelName,
      record_id: recordId
    }, {
      received(data) {
        window.EventsWithModelHandler.receivedEvent(data);
      },
    });
  });
};

$(document).on('turbolinks:load', function() {
  subscribeToEventsWithModel();
});
