require("bootstrap-daterangepicker/daterangepicker.css");
import 'bootstrap-daterangepicker';
window.moment = require('moment');
import 'moment-timezone';

function getMinDate(input) {
  return input.getAttribute('data-allow-past') == "false" ? new moment() : undefined;
}

function defaultStartTimeOffsetInSeconds(input) {
  return input.getAttribute('data-default-start-time-offset-in-seconds');
}

function enableDateFields($scope) {
  const dateRangeInput = $scope.find('input.single-daterange-with-time');
  function clearDate(event) {

    // don't submit the form.
    event.preventDefault();

    const $dateField = $(event.target).closest('.date-input').find('input');

    // clear the cloudinary id.
    $dateField.val(null);
  }

  
  dateRangeInput.each(function () {
    $(this).daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      autoUpdateInput: false,
      locale: {
        cancelLabel: 'Clear',
        format: 'MM/DD/YYYY hh:mm A'
      },
      drops: 'auto',
      minDate: getMinDate(this)
    });  
  })
  
  dateRangeInput.on('show.daterangepicker', function(ev, picker) {
    let minDate = getMinDate(this);
    if (minDate != undefined) {
      $(this).data('daterangepicker').minDate = minDate;
    }
    
    let startTimeOffset = defaultStartTimeOffsetInSeconds(this);
    if (this.value == "" && startTimeOffset) {
      picker.setStartDate(new moment().add(startTimeOffset, 'seconds'));
    }
    picker.setEndDate(null);
    picker.updateView();
  });

  dateRangeInput.on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('MM/DD/YYYY hh:mm A'));
  });

  dateRangeInput.on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });

  $scope.find(".date-input button.clear").click(clearDate);
}

$(document).on('turbolinks:load', function() {
  enableDateFields($('body'));
})

$(document).on('sprinkles:update', function(event) {
  enableDateFields($(event.target));
})

$(window).on('shown.bs.modal', function(event) { 
  enableDateFields($(event.target));
});
