function enableCloudinaryImages($scope) {
  function getCloudinarySignature(callback, paramsToSign) {
    $.ajax({
     url: "/account/cloudinary/upload_signatures/new",
     type: "GET",
     dataType: "text",
     data: {data: paramsToSign},
     complete: function() { console.log("complete") },
     success: function(signature, textStatus, xhr) { callback(signature); },
     error: function(xhr, status, error) { console.log(xhr, status, error); }
    });
  }

  function uploadImageForCloudinaryField(event) {

    // don't submit the form.
    event.preventDefault();

    // find a bunch of relevant elements.
    var $cloudinaryField = $(event.target).closest('.cloudinary-field');
    var $hiddenField = $cloudinaryField.find("input[type='hidden']");
    var $uploadButton = $cloudinaryField.find("button.upload");

    // prepare the list of default sources a user can upload an image from.
    var defaultSources = ['local'];
    if ($cloudinaryField.attr('data-google-api-key')) {
      defaultSources.push('image_search')
    }

    // configure cloudinary's uploader's options.
    // many of these are configurable at the point where the `shared/fields/cloudinary_image` partial is included.
    var options = {
      cloud_name: $cloudinaryField.attr('data-cloud-name'),
      apiKey: $cloudinaryField.attr('data-api-key'),
      upload_preset: $cloudinaryField.attr('data-upload-preset'),
      upload_signature: getCloudinarySignature,
      clientAllowedFormats: $cloudinaryField.attr('data-allowed-formats').split(','),
      multiple: false,
      sources: $cloudinaryField.attr('data-sources') ? $cloudinaryField.attr('data-sources').split(',') : defaultSources,
      search_by_rights: $cloudinaryField.attr('data-search-by-rights') == 'false' ? false : true, // default to true.
      google_api_key: $cloudinaryField.attr('data-google-api-key'),
      maxFileSize: $cloudinaryField.attr('data-max-file-size'),
      cropping: $cloudinaryField.attr('data-cropping') == 'true' ? true : false,
      show_skip_crop_button: $cloudinaryField.attr('data-show-skip-crop-button') == 'true' ? true : false,
      text: {
        en: {
          crop: {
            crop_btn: "Crop Image",
            skip_btn: "Upload As Is"
          }
        }
      }
    }

    // open cloudinary's upload widget.
    if (typeof cloudinary == 'undefined') {
      $(".modal").modal('hide');
      $('#cloudinaryErrorModal').modal('show');
    } else {
      cloudinary.openUploadWidget(options, function(error, notifications) {

        // after the user has successfully uploaded a single file ..
        $(notifications).each(function(index, notification) {
          if (notification.event == 'success') {
            var data = notification.info;
            var html_tag = $cloudinaryField.attr('data-html-tag');

            // update the cloudinary id field in the form.
            if (html_tag == 'video')
              $hiddenField.val(data.public_id + '.' + data.version + "." + data.format);
            else if ($cloudinaryField.attr('data-document') && data.format)
              $hiddenField.val(data.public_id + '.' + data.format);
            else
              $hiddenField.val(data.public_id);

            videoSourceTarget = $cloudinaryField.attr('data-video-source-target');
            if (videoSourceTarget) {
              videoSourceValue = $cloudinaryField.attr('data-video-source-value');
              $(videoSourceTarget).val(videoSourceValue);
            }

            // remove any existing image.
            $uploadButton.find('img').remove();
            $uploadButton.find('video').remove();

            var url;
            // generate a new image preview url.
            if ($cloudinaryField.attr('data-document') && data.public_id.includes('.')) {
              url = $cloudinaryField.attr('data-document-preview');
            } else {
              url = $cloudinaryField.attr('data-url-format').replace('CLOUDINARY_ID', data.public_id);
            }
            var width = $cloudinaryField.attr('data-width');
            var height = $cloudinaryField.attr('data-height');
            var $element = $("<" + html_tag + " src=\"" + url + "\" width=\"" + width + "\" height=\"" + height + "\" />");
            $uploadButton.prepend($element);

            // mark the image as present.
            $uploadButton.addClass('present');

            // populate the  width and height fields in Opt Ins
            if ($('.opt-in-image-data') && ($hiddenField.is('#opt_in_image_value') || $hiddenField.is('#opt_in_confirmation_image_value'))) {
              $('.opt-in-image-width').val(Math.min(1046, data.width))
              $('.opt-in-image-height').val(Math.min(1046, data.height))
              $('.opt-in-image-data').removeClass('d-none')
            }

            // TODO: (Ilia) opt ins and upsell has same structure for custom image attributes, should be created new field with general attributes
            // populate the  width and height fields in Upsell
            if ($('.upsell-image-data') && ($hiddenField.is('#upsell_image_value'))) {
              $('.upsell-image-width').val(Math.min(1046, data.width))
              $('.upsell-image-height').val(Math.min(1046, data.height))
              $('.upsell-image-data').removeClass('d-none')
            }
            if ($('.upsell-confirmation-image-data') && ($hiddenField.is('#upsell_confirmation_image_value'))) {
              $('.upsell-confirmation-image-width').val(Math.min(1046, data.width))
              $('.upsell-confirmation-image-height').val(Math.min(1046, data.height))
              $('.upsell-confirmation-image-data').removeClass('d-none')
            }
          }

        });
      });
    }

  }

  function clearImageFromCloudinaryField(event) {

    // don't submit the form.
    event.preventDefault();

    // find a bunch of relevant elements.
    var $cloudinaryField = $(event.target).closest('.cloudinary-field');
    var $hiddenField = $cloudinaryField.find("input[type='hidden']");
    var $uploadButton = $cloudinaryField.find("button.upload");

    // clear the cloudinary id.
    $hiddenField.val(null);

    // remove any existing image from the button.
    $uploadButton.find('img').remove();
    $uploadButton.find('video').remove();

    // mark the image as *not* present.
    $uploadButton.removeClass('present');

  }

  $scope.find(".cloudinary-field button.upload").click(uploadImageForCloudinaryField);
  $scope.find(".cloudinary-field button.clear").click(clearImageFromCloudinaryField);
}

$(document).on('turbolinks:load', function() {
  enableCloudinaryImages($('body'));
})

$(document).on('sprinkles:update', function(event) {
  enableCloudinaryImages($(event.target));
})

$(window).on('shown.bs.modal', function(event) {
  enableCloudinaryImages($(event.target));
});
