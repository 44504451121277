$(document).on('turbolinks:load', function() {
  $("#copyLink").on('click', function() {
    var tooltip = bootstrap.Tooltip.getInstance(this);
    const text = $(this).find('.inner-link')[0].innerText;
    navigator.clipboard.writeText(text);
    tooltip.show();
    setTimeout(() => { tooltip.hide() }, 10000);
  });
  
  $('.normal-nav a[data-bs-toggle="tab"]').on('shown.bs.tab', event => {
    $('#mobileNav').val(event.target.id);
  });

  $('#mobileNav').on('change', event => {
    console.log(event.target);
    tab = document.getElementById(event.target.value);
    bootstrap.Tab.getOrCreateInstance(tab).show();
  })
});
