$(document).on('turbolinks:load', () => {
  var iframeSelector = document.getElementById('video-iframe') || document.getElementById('compliance-video-iframe');
  if (iframeSelector) {
    $(window).on('blur', () => {
      // We need an interval here since document.activeElement doesn't get set to the iframe yet when the event occurs.
      var checkInterval = setInterval(()=>{
        if (document.activeElement === iframeSelector) {
          $('.poster').hide();
          clearInterval(checkInterval);
        }
      }, 100)
      setTimeout(() => { clearInterval(checkInterval)}, 1000);
    });
  }
});
