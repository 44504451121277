import 'spectrum-colorpicker'

$(document).on('turbolinks:load', function() {
    $('#opt_in_button_color, #opt_in_button_text_color').spectrum({
        showInput: true,
        containerClassName: 'opt-in-colorpicker-container',
        replacerClassName: 'opt-in-colorpicker-replacer',
        preferredFormat: "hex"
    });
});
