$(document).on('turbolinks:load', function() {
  $(".toggle-password").click(function() {
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
      input.attr("type", "text");
      $(this).html('visibility_off');
    } else {
      input.attr("type", "password");
      $(this).html('visibility');
    }
  });
  
  $(".toggle-password-legacy").click(function() {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });
  const regEx = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,128}$")
  const currentEmail="<%=current_user.email.downcase%>"
  $(".user-password").on('change', function() {
    const passwordValue = $(this).val();
    const formElement = $(this).parents('form');

    if (regEx.test(passwordValue) && currentEmail!== passwordValue.toLowerCase()) {
      formElement.find(':submit').prop('disabled', false);
      $('.password-error').css('display','none');
    } else {
      $('.password-error').css('display','block');
      formElement.find(':submit').prop('disabled', true);
    }
  });

  $(".user-confirm-password").on('keyup', function() {
    const passwordValue = $('.user-password').val();
    const confirmPasswordValue = $(this).val();

    const formElement = $(this).parents('form');
    if (passwordValue !== confirmPasswordValue) {
      $('.password-confirm-error').css('display','block');
      formElement.find(':submit').prop('disabled', true);
    } else {
      $('.password-confirm-error').css('display','none');
      formElement.find(':submit').prop('disabled', false);
    }
  });
});
